import headerImage2024BE from './assets/images/be.jpg'
import headerImage2024NL from './assets/images/nl.jpg'

const element = document.getElementById('verdubbel-je-jaarloon')
const campaign = element.getAttribute('data-campaign') ?? 'qmusic-be'

const configs = {
  'qmusic-be': {
    backgroundImage: headerImage2024BE,
    apiBaseUrl: 'https://qmusic.be/',
    station: 'qmusic_be',
    theme: 'qmusic-theme',
    numberOfQuestions: 10,
    numberOfSkips: 10,
    questionsUrl: 'https://spreadsheet.radio.dpgmedia.cloud/prod/fetch?tableId=stIIslNS35bXCOpPhw&pageSize=500',
    colors: {
      background: '#ED3624',
      backgroundAlternate: '#FFF',
      text: '#FFF',
      textAlternate: '#333',
      button: '#FFF',
      buttonText: '#ED3624',
    },
    form: {
      id: 1615,
      questions: [
        {
          text: 'Voornaam',
          type: 'text',
          required: true,
          defaultValue: (profile) => profile?.first_name,
          id: 14517,
        },
        {
          text: 'Achternaam',
          type: 'text',
          required: true,
          defaultValue: (profile) => profile?.last_name,
          id: 14519,
        },
        {
          text: 'E-mail',
          type: 'email',
          required: true,
          defaultValue: (profile)=> profile?.email,
          id: 14527,
        },
        {
          text: 'GSM-nummer',
          type: 'tel',
          required: true,
          defaultValue: (profile) => profile?.mobile,
          id: 14525,
        },
        {
          text: 'Geboortedatum',
          type: 'date',
          required: true,
          defaultValue: (profile) => profile?.birthday,
          id: 14521,
        },
        {
          text: 'Woonplaats',
          type: 'text',
          required: true,
          id: 14523,
        },
        {
          text: 'Wat is je (hoofd)beroep?',
          type: 'text',
          required: true,
          id: 14529,
        },
        {
          text: 'Wat was je netto maandloon vorige maand?',
          type: 'text',
          required: true,
          id: 14531,
        },
        {
          checkboxText:
            'Ja, ik ga akkoord met de <a href="https://privacy.dpgmedia.be/nl/document/terms-of-use" target="_blank">algemene gebruiksvoorwaarden</a> en het <a href="http://dpgmedia.be/nl/privacy" target="_blank">wedstrijdreglement</a> en ik erken de <a href="http://dpgmedia.be/nl/privacy" target="_blank">privacy- en cookieverklaring</a> te hebben gelezen, inclusief de verwerking van mijn persoonsgegevens voor een gepersonaliseerde service.',
          text: '',
          type: 'checkbox',
          id: 14535,
        },
        {
          checkboxText:
            'Ja, ik wil graag updates van Qmusic ontvangen via e-mail met daarin de leukste momenten van Q, behind-the-scenes fragmenten en nieuws over wedstrijden en events',
          text: '',
          type: 'checkbox',
          id: 14533,
        },
      ],
    },
    copy: {
      preview: {
        title: 'De Oefenquiz',
        body: "Kan jij 10 vragen juist beantwoorden in 60 seconden? Schrijf je dan zeker in voor 'Verdubbel Je Loon In 60 Seconden'!",
        button: "Start",
        secondaryButton: 'Uitleg quiz',
      },
      rules: {
        title: 'Verdubbel Je Loon In 60 Seconden <br> de oefenquiz',
        body: `
        <h2>Uitleg Quiz</h2>
        <p>Als je meespeelt op de radio moet je zelf met het antwoord komen. Om te oefenen maken we het iets makkelijker en krijg je multiplechoicevragen.</p>
        <h2>60 SECONDEN</h2>
        <p>Je krijgt 60 seconden om de 10 vragen juist te beantwoorden. Als je een FOUT antwoord geeft is de quiz meteen afgelopen! Dus als je twijfelt over het antwoord kan je beter passen.</p>
        <h2>Pasvragen</h2>
        <p>Je mag 1 keer passen per vraag, die krijg je dan op het einde een 2de en laatste keer. <br> Als je een FOUT antwoord geeft is het AFGELOPEN!</p>
        `,
        button: "Start",
      },
      game: {
        title: 'Verdubbel Je Loon In 60 Seconden De Oefenquiz',
        skip: 'passen'
      },
      results: {
        title: 'Verdubbel Je Loon In 60 Seconden De Oefenquiz',
        subtitle: (score) => {
          if (score < 7) return "Jammer"
          else if (score < 9) return "Bijna gelukt"
          else if (score < 10) return "Goed gedaan"
          else return "Proficiat"
        },
        score: "Je scoort:",
        body: (score) => {
          if (score < 7) return "Misschien toch nog even oefenen? Als je gekozen wordt als deelnemer voor 'Verdubbel Je Loon in 60 Seconden' kan je maar beter goed voorbereid zijn."
          else if (score < 9) return "Niet slecht! Als je nog niet ingeschreven bent, zeker je kans wagen. En wie weet word je eruit gepikt om deel te nemen aan 'Verdubbel Je Loon In 60 Seconden' bij Maarten & Dorothee!"
          else if (score < 10) return "Bijna alles juist! Als je nog niet ingeschreven bent, waag zeker je kans. En wie weet word je eruit gepikt om deel te nemen aan 'Verdubbel Je Loon In 60 Seconden' bij Maarten & Dorothee!"
          else return "Alles juist! Als je nog niet ingeschreven bent, waag zeker je kans. En wie weet word je eruit gepikt om deel te nemen aan 'Verdubbel Je Loon In 60 Seconden' bij Maarten & Dorothee!"
        },
       button: "Schrijf je in",
        secondaryButton: "Quiz opnieuw"
      },
      form: {
        title: "Ik wil mij inschrijven voor 'Verdubbel Je Loon In 60 Seconden'!",
        submitted: "Bedankt voor je inschrijving voor 'Verdubbel Je Loon in 60 Seconden'!",
      }
    },
  },
  'qmusic-nl': {
    backgroundImage: headerImage2024NL,
    apiBaseUrl: 'https://qmusic.nl/',
    station: 'qmusic_nl',
    theme: 'qmusic-theme',
    numberOfQuestions: 10,
    numberOfSkips: 2,
    questionsUrl: 'https://spreadsheet.radio.dpgmedia.cloud/prod/fetch?tableId=std5VPl1u6a991I1aJ&pageSize=500',
    colors: {
      background: '#ED3624',
      backgroundAlternate: '#FFF',
      text: '#FFF',
      textAlternate: '#333',
      button: '#FFF',
      buttonText: '#ED3624',
    },
    form: {
      id: 1129,
      questions: [
        {
          text: 'Voornaam',
          type: 'text',
          required: true,
          defaultValue: (profile) => profile?.first_name,
          id: 7597,
        },
        {
          text: 'Achternaam',
          type: 'text',
          required: true,
          defaultValue: (profile) => profile?.last_name,
          id: 7599,
        },
        {
          text: 'Wat is je 06-nummer?',
          type: 'tel',
          required: true,
          defaultValue: (profile) => profile?.mobile,
          id: 7601,
        },
        {
          text: 'Wat is je e-mailadres?',
          type: 'email',
          required: true,
          defaultValue: (profile)=> profile?.email,
          id: 7603,
        },
        {
          text: 'Wat is je geboortedatum?',
          type: 'date',
          required: true,
          defaultValue: (profile) => profile?.birthday,
          id: 7605,
        },
        {
          text: 'Wat is je beroep?',
          type: 'text',
          required: true,
          id: 7607,
        },
        {
          text: 'Wat is je netto maandsalaris?',
          type: 'text',
          required: true,
          id: 7609,
        },
        {
          text: 'Waarom wil jij je salaris verdubbelen?',
          type: 'text',
          required: true,
          id: 7611,
        },
        {
          checkboxText:
            'Ja, ik wil graag updates van Qmusic ontvangen via e-mail met daarin de leukste momenten van Q, behind-the-scenes fragmenten en nieuws over wedstrijden en events',
          text: '',
          type: 'checkbox',
          id: 7627,
        },
      ],
    },
    copy: {
      preview: {
        title: 'Verdubbel je Salaris Oefenquiz!',
        body: "Oefenen voor Verdubbel je Salaris in 60 seconden? Dat kan hier! Je krijgt 10 vragen, alles wat jij moet doen is ze binnen 60 seconden goed beantwoorden.",
        button: "Let's Go!",
        secondaryButton: 'Uitleg',
      },
      rules: {
        title: 'Verdubbel je Salaris Oefenquiz!',
        body: `
        <h2>Uitleg</h2>
        <p>Speel 10 multiple choice vragen in 60 seconden.</p>
        <h2>60 SECONDEN</h2>
        <p>Je krijgt 10 vragen, beantwoord ze alle 10 juist.</p>
        <h2>Pasvragen</h2>
        <p>Je mag maximaal 2 gepaste vragen open hebben staan, die vragen krijg je dan op het einde - binnen die minuut- nog eens en op die moeten goed beantwoord worden. Als je een FOUT antwoord geeft is het spel meteen AFGELOPEN!</p>
        `,
        button: "Let's Go!",
      },
      game: {
        title: 'Verdubbel je Salaris Oefenquiz!',
        skip: 'Pas'
      },
      results: {
        title: 'Verdubbel je Salaris Oefenquiz!',
        subtitle: (score) => {
          if (score < 7) return "Helaas"
          else if (score < 9) return "Bijna gelukt"
          else if (score < 10) return "Goed gedaan"
          else return "Proficiat"
        },
        score: "Je scoort:",
        body: (score) => {
          if (score < 7) return "Probeer het nog een keer en bereid je helemaal voor op Verdubbel je Salaris!"
          else return "Super goed gedaan! Schrijf je meteen in voor Verdubbel je Salaris als je dat nog niet gedaan hebt. En veel succes!"
        },
       button: "Schrijf je alvast in",
       secondaryButton: "Probeer het opnieuw"
      },
      form: {
        title: "Schrijf je in voor Verdubbel je Salaris in 60 seconden",
        submitted: "Bedankt voor je inschrijving voor 'Verdubbel je Salaris in 60 seconden'!",
      }
    },
  },
}

export default configs
export const CAMPAIGN_NAME = campaign
export const CAMPAIGN_CONFIG = configs[campaign]
